import { useParams } from "react-router-dom";
import { ServiceDetail } from "../../containers";
import { MainLayout } from "../../layouts";
import { navigationItems, services } from "../../constants";
import { BreadCrumbNavigation, PaddedBox } from "../../components";
import { Container } from "@mui/material";

export const ServiceDetailPage = () => {
  const { id } = useParams();

  const service = services.find((service: any) => service.id === id);

  const activeServiceNavItem = Object.values(navigationItems).find(
    (item) => item.key === id
  );

  let breadCrumbItems = [navigationItems.home, navigationItems.services];

  if (activeServiceNavItem) {
    breadCrumbItems.push(activeServiceNavItem);
  }

  const details = {
    key: service?.id ?? "",
    title: service?.title ?? "",
    description: service?.description ?? "",
    image: service?.image ?? "",
  };

  return (
    <MainLayout>
      <PaddedBox>
        <Container>
          <BreadCrumbNavigation items={breadCrumbItems} backLink="/services" />
        </Container>
        <ServiceDetail details={details} />
      </PaddedBox>
    </MainLayout>
  );
};

export default ServiceDetailPage;
