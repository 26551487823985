import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button, Heading } from "../../ui";

export const AboutUsLanding = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#eee",
      }}
    >
      <Container
        sx={{
          py: 5,
        }}
      >
        <Heading>About the Company</Heading>

        <Grid container>
          <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            <Typography>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Agile Healthcare Consultancy{" "}
              </span>
              is a full-service consulting firm dedicated to providing tailored
              healthcare solutions to clients to Middle Easter part of world.
              Our team of experienced healthcare professionals offers a range of
              services that are designed to optimize healthcare organizations'
              operations, increase efficiency, and improve patient outcomes.
            </Typography>
            <br></br>
            <Typography>
              Our services include Revenue Cycle Management, which covers CPT
              and ICD-10 coding services, reconciliation of existing insurance
              claims, and comprehensive revenue cycle analysis. We help clients
              identify revenue leakages and implement strategies to boost their
              revenue streams.
            </Typography>

            <Link to="/aboutus">
              <Button>Read More</Button>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              py: 2,
            }}
            order={{ xs: 1, sm: 2 }}
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                backgroundImage: "url('assets/img/about.jpg')",
                backgroundSize: "cover",
                minHeight: "300px",
              }}
            ></div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUsLanding;
