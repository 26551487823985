import { Container } from "@mui/material";
import { BreadCrumbNavigation, PaddedBox } from "../../components";
import { AboutUs } from "../../containers";
import { MainLayout } from "../../layouts";
import { navigationItems } from "../../constants";

const breadCrumbItems = [navigationItems.home, navigationItems.aboutus];

export const AboutUsPage = () => {
  return (
    <MainLayout>
      <PaddedBox>
        <Container>
          <BreadCrumbNavigation
            items={breadCrumbItems}
            backLink={navigationItems.home.link}
          />
        </Container>
        <AboutUs />
      </PaddedBox>
    </MainLayout>
  );
};

export default AboutUsPage;
