import { Container } from "@mui/material";
import { BreadCrumbNavigation, PaddedBox } from "../../components";
import { navigationItems } from "../../constants";
import { Services } from "../../containers";
import { MainLayout } from "../../layouts";

const breadCrumbItems = [navigationItems.home, navigationItems.services];

export const ServicesPage = () => {
  return (
    <MainLayout>
      <PaddedBox>
        <Container>
          <BreadCrumbNavigation items={breadCrumbItems} backLink="/" />
        </Container>
        <Services />
      </PaddedBox>
    </MainLayout>
  );
};

export default ServicesPage;
