import { Typography } from "@mui/material";

export const serviceContent = [
  {
    key: "revenue-cycle-management",
    content: (
      <>
        <Typography
          sx={{
            py: 1,
          }}
        >
          Comprehensive revenue cycle management plays a vital role in the
          financial success of healthcare organizations. Efficiently managing
          ICD & CPT coding, reconciliation, denial management, and payment
          follow-up processes ensures accurate reimbursement, reduces claim
          denials, improves cash flow, and promotes compliance with coding and
          billing regulations. By implementing effective RCM strategies,
          healthcare providers can optimize revenue, enhance operational
          efficiency, and focus on delivering high-quality patient care.
        </Typography>
        <Typography
          sx={{
            py: 1,
          }}
        >
          ICD & CPT Coding: Accurate and comprehensive coding is essential
          for proper claim submission and reimbursement. Healthcare providers
          rely on the International Classification of Diseases (ICD) and Current
          Procedural Terminology (CPT) coding systems to translate patient
          diagnoses and services into standardized codes. Implementing robust
          coding practices ensures that claims are correctly categorized,
          reducing the risk of claim denials, audit penalties, and revenue loss.
        </Typography>
        <Typography
          sx={{
            py: 1,
          }}
        >
          Reconciliation: Reconciliation involves the process of verifying
          and aligning financial data across various stages of the revenue
          cycle. It ensures that the charges captured, services rendered, and
          payments received are accurately recorded and accounted for. By
          conducting regular reconciliations, healthcare organizations can
          identify discrepancies, resolve billing errors, and maintain financial
          transparency. This practice helps prevent revenue leakage and
          strengthens the overall financial health of the organization.
        </Typography>
        <Typography
          sx={{
            py: 1,
          }}
        >
          Denial Management: Claim denials are a common challenge in
          healthcare revenue cycles, leading to delayed or lost revenue.
          Effective denial management involves systematically tracking and
          resolving denied claims in a timely manner. By analysing denial
          patterns, identifying root causes, and implementing corrective
          actions, healthcare providers can minimize denials, optimize claim
          reimbursement, and accelerate revenue flow.
        </Typography>
        <Typography
          sx={{
            py: 1,
          }}
        >
          Payment Follow-Up: Timely and persistent payment follow-up is
          crucial to ensure prompt reimbursement. It involves tracking
          outstanding claims, communicating with payers, and addressing any
          issues or discrepancies that may delay payment. By establishing robust
          processes for payment follow-up, healthcare organizations can minimize
          payment delays, reduce accounts receivable days, and improve cash
          flow.
        </Typography>
      </>
    ),
  },
  {
    key: "healthcare-accreditations",
    content: (
      <>
        <Typography
          sx={{
            py: 1,
          }}
        >
          Enhance the credibility of your healthcare facility on an
          international scale with our comprehensive guidance and support for
          international healthcare accreditations. At Agile, we understand the
          importance of meeting stringent accreditation standards to improve
          patient trust and community recognition. Our experienced consultants
          are here to assist you in achieving and maintaining international
          accreditations, such as JCI, Canadian accreditations, NABL, ISO, and
          CAP, among others. With our expertise, you can elevate your facility's
          quality of care and operational excellence. Our team of consultants
          brings decades of experience in working with healthcare facilities to
          meet international accreditation requirements. We have an in-depth
          understanding of the specific criteria and standards set by various
          accreditation bodies. Our consultants will guide you through the
          accreditation process, ensuring that your facility is well-prepared to
          meet the rigorous standards. By leveraging our expertise, you can
          navigate the complexities of accreditation smoothly and efficiently.
        </Typography>
        <Typography
          sx={{
            py: 1,
          }}
        >
          Identifying areas where your facility falls short of accreditation
          standards is essential for improvement. Our consultants conduct a
          comprehensive gap analysis, evaluating your current practices against
          accreditation requirements. We provide you with detailed reports
          highlighting the gaps and recommend strategies to address them
          effectively. Our goal is to help you enhance your facility's
          operations, patient safety, and overall quality of care. The
          competence and knowledge of your staff play a vital role in achieving
          and maintaining international accreditations. We offer customized
          training programs for your internal staff to ensure they are
          well-equipped to meet accreditation standards. Our training sessions
          cover a wide range of topics, including patient safety, infection
          control, quality improvement, and compliance with international
          regulations. By investing in the professional development of your
          staff, you can foster a culture of continuous improvement and deliver
          exceptional care to your patients. Accreditation bodies require
          healthcare facilities to have well-defined Standard Operating
          Procedures (SOPs) in place. Our consultants will work closely with
          your team to develop and implement SOPs that align with the
          requirements of the relevant accreditation body. We ensure that your
          SOPs are comprehensive, up-to-date, and reflect best practices in the
          industry. Additionally, we provide guidance on documentation
          management, helping you streamline your processes and maintain a
          paperless environment. Embracing digital solutions can significantly
          improve the efficiency of your healthcare facility. We assist in
          digitalizing your accreditation-related documents, making them easily
          accessible and reducing the reliance on paper-based systems. Our
          consultants will help you integrate digital platforms and tools into
          your day-to-day operations, enabling smoother workflows, enhanced data
          management, and improved communication among staff members. Our
          solutions are designed to be cost-effective and tailored to your
          facility's unique needs. We understand the financial constraints faced
          by healthcare facilities. Our guidance and support services are
          designed to be affordable, providing you with comprehensive assistance
          at a reasonable cost. We believe that every healthcare facility should
          have access to the expertise needed to achieve international
          accreditations and enhance the quality of care provided to patients.
        </Typography>
      </>
    ),
  },
  {
    key: "healthcare-facility-licensing",
    content: (
      <>
        <Typography
          sx={{
            py: 1,
          }}
        >
          We understand the importance of ensuring that healthcare facilities
          meet the necessary standards and regulations set forth by the Ministry
          of Public Health- Qatar. Whether you are establishing a new clinic,
          renewing an existing license, or managing a company clinic, we are
          here to provide you with comprehensive guidance and support throughout
          the licensing process. Our team of experienced professionals is
          well-versed in the Standard Operating Procedures (SOPs) defined by the
          Ministry of Public Health. We stay up to date with the latest
          regulations and guidelines to ensure that your healthcare facility
          meets all the necessary requirements for licensing. You can rely on us
          to navigate the complex landscape of healthcare regulations on your
          behalf. We offer digitalization services for your SOPs, helping you
          transition to a paperless system. Our experts will assist you in
          converting your SOPs environmentally friendly manner. Compliance with
          healthcare regulations is crucial for the success and safety of your
          facility. Our team will conduct a comprehensive gap analysis to
          identify any areas where your facility may fall short of the required
          standards. We will provide you with detailed reports and
          recommendations, enabling you to address any gaps and ensure full
          compliance. Our experts will guide you through the process of
          implementing necessary changes, enhancing the quality of your
          healthcare services. Well-trained staff is the cornerstone of a
          successful healthcare facility. We offer specialized training programs
          tailored to meet the unique needs of your facility and staff members.
          Our training sessions cover a wide range of topics, including
          infection control, patient safety, emergency preparedness, and
          regulatory compliance. By equipping your staff with the necessary
          knowledge and skills, we help create a safe and high-quality
          healthcare environment for your patients. Preparing for the final
          inspection can be a stressful process. To ensure a smooth and
          successful inspection, we conduct mock drills to simulate real-life
          scenarios and test your facility's readiness. These drills help
          identify any areas that may require further improvement, allowing you
          to address them proactively. Our team will guide you through the mock
          drill process, providing valuable feedback and suggestions for
          enhancing your facility's performance.
        </Typography>
      </>
    ),
  },
  {
    key: "hms-his-software",
    content: (
      <>
        <Typography
          sx={{
            py: 1,
          }}
        >
          We provide customized Hospital Management System (HMS) and Healthcare Information System (HIS) software solutions that meet our clients' unique needs. Our team works closely with clients to understand their specific requirements and develop software solutions that streamline their operations, increase efficiency, and improve patient care.
        </Typography>
      </>
    ),
  },
];
