import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { services } from "../../constants";
import { Button, Heading } from "../../ui";
import { PaddedBox } from "../../components";

export const ServiceCard = ({ title, description, image, link }: any) => {
  return (
    <Box
      className="animate__animated animate__bounce"
      sx={{
        p: 2,
        // border: "1px dashed black",
        height: "100%",
        mx: 1,
        backgroundColor: "#eee",
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        "&:hover": {
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: "900",
          padding: "10px 0px",
          color: "rgb(75, 116, 170)",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: "100px",
          borderBottom: "2px solid #9fcc46",
          marginBottom: "10px",
        }}
      ></Box>
      <Typography>{description}</Typography>

      <Link to={link}>
        <Button>Details</Button>
      </Link>
    </Box>
  );
};

export const Services = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
      }}
    >
      <PaddedBox>
        <Container>
          <Heading>Our Services</Heading>
          <Grid container>
            {services.map((service: any) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{
                  p: 1,
                }}
              >
                <ServiceCard
                  title={service.title}
                  description={service.description}
                  image={service.image}
                  link={service.link}
                ></ServiceCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </PaddedBox>
    </Box>
  );
};

export default Services;
