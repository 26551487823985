import { ArrowBackIosNew } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState } from "react";

const ArrowDown = () => {
  return (
    <ArrowBackIosNew
      sx={{
        transform: "rotate(-90deg)",
      }}
    />
  );
};

const ArrowUp = () => {
  return (
    <ArrowBackIosNew
      sx={{
        transform: "rotate(90deg)",
      }}
    />
  );
};

interface FAQItemProps {
  open?: boolean;
  title: string;
  content: string;
}

export interface FAQProps {
  title?: string;
  openedIndex?: number;
  items: FAQItemProps[];
}

export const FAQItem = ({ title, content, open }: FAQItemProps) => {
  const [isOpen, setIsOpen] = useState(open ?? false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#eee",
          borderLeft : "3px solid #9fcc46",
          color:"black",
          px: 3,
        }}
        onClick={() => toggleOpen()}
      >
        <Typography
          sx={{
            my: 2,
            flexGrow: 1,
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>

        {isOpen ? <ArrowUp /> : <ArrowDown />}
      </Box>

      <Typography
        sx={{
          my: 2,
          height: isOpen ? "auto" : "0px",
          overflow: "hidden",
          transition: "0.5s ease",
          backgroundColor: "#eee",
          p: isOpen ? 3 : 0,
        }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export const FAQ = ({ title, items }: FAQProps) => {
  return (
    <Box
      sx={{
        my: 2,
      }}
    >
      {title && <Typography variant="h4">{title}</Typography>}
      {items.map((item: FAQItemProps, index: number) => (
        <FAQItem open={index === 0} title={item.title} content={item.content} />
      ))}
    </Box>
  );
};

export default FAQ;
