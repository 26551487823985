import { Container, Grid, Typography } from "@mui/material";
import { Heading } from "../../ui";

export const AboutUs = () => {
  return (
    <Container
      sx={{
        py: 5,
      }}
    >
      <Heading>About the Company</Heading>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Agile Healthcare Consultancy{" "}
            </span>
            is a full-service consulting firm dedicated to providing tailored
            healthcare solutions to clients to Middle Easter part of world. Our
            team of experienced healthcare professionals offers a range of
            services that are designed to optimize healthcare organizations'
            operations, increase efficiency, and improve patient outcomes.
          </Typography>
          <br></br>
          <Typography
            sx={{
              py: 1,
            }}
          >
            Our services include Revenue Cycle Management, which covers CPT and
            ICD-10 coding services, reconciliation of existing insurance claims,
            and comprehensive revenue cycle analysis. We help clients identify
            revenue leakages and implement strategies to boost their revenue
            streams.
          </Typography>
          <Typography
            sx={{
              py: 1,
            }}
          >
            We also provides guidance on international healthcare accreditation,
            such as JCI and CAP. Our consultants have extensive experience
            working with healthcare facilities to meet accreditation
            requirements and improve their overall quality of care.
          </Typography>
          In addition to accreditation guidance, we offer healthcare facility
          setup and licensing services. Our team provides end-to-end support to
          healthcare providers, from facility design to regulatory compliance
          and licensing.
          <Typography
            sx={{
              py: 1,
            }}
          >
            We also provide customized Hospital Management System (HMS) and
            Healthcare Information System (HIS) software solutions that meet our
            clients' unique needs. Our team works closely with clients to
            understand their specific requirements and develop software
            solutions that streamline their operations, increase efficiency, and
            improve patient care.
          </Typography>
          <Typography>
            At Agile International , we pride ourselves on our commitment to
            delivering high-quality services and achieving measurable results
            for our clients. We understand the unique challenges facing
            healthcare providers today and are dedicated to providing tailored
            solutions that help our clients achieve their goals.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              backgroundImage: "url('assets/img/about.jpg')",
              backgroundSize: "cover",
            }}
          ></div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
