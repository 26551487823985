import { Box, Container } from "@mui/material";
import { FAQ, PaddedBox } from "../../components";
import { faq } from "../../data";
import { Button, Heading } from "../../ui";
import { Link } from "react-router-dom";

export const FAQContainerLanding = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f7f7f7",
      }}
    >
      <PaddedBox>
        <Container>
          <Heading>Frequently Asked Questions</Heading>
          <FAQ items={faq} />
          <Link to="/faq">
            <Button>More FAQ</Button>
          </Link>
        </Container>
      </PaddedBox>
    </Box>
  );
};

export default FAQContainerLanding;
