import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { navigationItems } from "../../constants";
import { Email, Home, Phone, Instagram, LinkedIn } from "@mui/icons-material";
import { Logo, PaddedBox } from "../../components";

const FooterContactInfoItem = ({ icon, label, link }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        py: 1,
      }}
    >
      <Link to={link} target="_blank" style={{
        display: "flex"
      }}>
        <Box sx={{ mr: 2 }}>{icon}</Box>
        <Typography
          sx={{
            fontSize: {
              xs: "12px",
              sm: "16px",
            },
          }}
        >
          {label}
        </Typography>
      </Link>
    </Box>
  );
};

const footerItemGroups = [
  {
    id: "services",
    title: "Services",
    items: [
      navigationItems.revenueCycleManagementService,
      navigationItems.healthcareAccreditationService,
      navigationItems.healthcareFacilityLicensingService,
      navigationItems.hishmsSoftwareService,
    ],
  },
  {
    id: "links",
    title: "Links",
    items: [
      navigationItems.aboutus,
      navigationItems.contactus,
      navigationItems.faq,
    ],
  },
];

const FooterTop = () => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(26, 25, 25)",
        color: "white",
        py: {
          xs: "20px",
          sm: "80px",
        },
      }}
    >
      <PaddedBox>
        <Container>
          <Typography
            sx={{
              fontWeight: "bold",
              mb: 3,
              fontSize: {
                xs: "18px",
              },
              color: "rgb(75, 116, 170)",
            }}
          >
            <Logo />
            Agile Healthcare Consultancy
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <FooterContactInfoItem
                icon={<Email />}
                label="info@agilehealthcon.com"
              ></FooterContactInfoItem>
              <FooterContactInfoItem
                icon={<Phone />}
                label="+974 505 78 141"
              ></FooterContactInfoItem>
              <FooterContactInfoItem
                icon={<Home />}
                label="Pearl Tower | 7th Floor | Office No.703 Umm Ghuwailina | Doha | Qatar"
              ></FooterContactInfoItem>
              <FooterContactInfoItem
                icon={<Instagram />}
                label="@agil.e505"
                link={"https://instagram.com/agil.e505"}
              ></FooterContactInfoItem>
              <FooterContactInfoItem
                icon={<LinkedIn />}
                label="agile-healthcare-consultancy-b7020a287"
                link={"https://www.linkedin.com/in/agile-healthcare-consultancy-b7020a287"}
              ></FooterContactInfoItem>
            </Grid>

            {footerItemGroups.map((group) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "left",
                    lg: "center",
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      my: 2,
                      fontSize: {
                        xs: "16px",
                        sm: "22px",
                      },
                    }}
                  >
                    {group.title}
                  </Typography>
                  {group.items.map((item) => (
                    <Link
                      to={item.link}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Typography
                        sx={{
                          my: 1,
                          fontSize: {
                            xs: "12px",
                            sm: "16px",
                          },
                          transition: "0.5s ease",
                          color: "#eee",
                          "&:hover": {
                            color: "#9fcc46",
                          },
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Link>
                  ))}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </PaddedBox>
    </Box>
  );
};

const FooterBottom = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#2e2e2e",
        color: "#eee",
        display: "flex",
        justifyContent: "center",
        py: 2,
        fontSize: { xs: "12px", sm: "16px" },
      }}
    >
      © Agile Healthcare Consultancy
    </Box>
  );
};

export const Footer = () => {
  return (
    <Box>
      <FooterTop />
      <FooterBottom />
    </Box>
  );
};

export default Footer;
