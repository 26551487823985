import Menu from "./Menu";
import { useState } from "react";
import { Box } from "@mui/material";
import { Close, Menu as Menuicon } from "@mui/icons-material";
import WhatsAppLink from "../WhatsappLink";
import Logo from "../Logo";
import { usePageScroll } from "../../hooks";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { scrolled } = usePageScroll();

  const NavbarTop = () => {
    return (
      <Box
        sx={{
          transition: "0.8s ease",
          backgroundColor: "white",
          alignItems: "center",
          px: {
            xs: "10px",
            sm: "30px",
            md: "150px",
          },
          display  :"flex" ,
          // display: {
          //   xs: "none",
          //   sm: "flex",
          // },
          py: 2,
        }}
      >
        <Logo />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <WhatsAppLink />
        </Box>
      </Box>
    );
  };

  const NavbarBottom = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#4b74aa",
          px: {
            xs: "10px",
            sm: "30px",
            md: "150px",
          },
          py: 1,
        }}
      >
        <Box
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
          sx={{
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: "#3c6599",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#345d91",
              },
            }}
          >
            {menuOpen ? (
              <Close
                sx={{
                  height: "50px",
                  color: "white",
                }}
              />
            ) : (
              <Menuicon
                sx={{
                  height: "50px",
                  color: "white",
                }}
              />
            )}
          </Box>
        </Box>

        {menuOpen && (
          <Menu
            onMenuItemClick={() => {
              setMenuOpen(false);
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0px",
        zIndex: "9999",
        width: "100%",
      }}
    >
      <NavbarTop />
      <NavbarBottom />
    </Box>
  );
};

export default Navbar;
