import { WhatsApp } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

export const WhatsAppLink = () => {
  return (
    <Link to="https://wa.me/97450578141" target={"_blank"}>
      <Tooltip title="+974 505 78 141">
        <>
          <IconButton>
            <WhatsApp
              sx={{
                color: "green",
              }}
            />
          </IconButton>
          +974 505 78 141
        </>
      </Tooltip>
    </Link>
  );
};

export default WhatsAppLink;
