export const faq = [
  {
    title: "What services do we offer",
    content: `We offer a range of services to meet the unique needs of our clients, including:
  
              Revenue Cycle Management: We provide ICD and CPT coding services, reconciliation of existing insurance claims, and denial management. Our team helps clients identify revenue leakages and implement strategies to boost their revenue streams.
              
              Healthcare Accreditations: We offer guidance on international healthcare accreditations, such as JCI, Canadian accreditations, NABL, ISO, and CAP. Our consultants have extensive decades of experience working with healthcare facilities to meet accreditation requirements and improve their overall quality of care.
              
              Healthcare Facility Licensing: Our team provides end-to-end support to healthcare providers, from facility design with regulatory compliance and licensing.
              
              HMS and HIS Software: We provide customized Hospital Management System (HMS) and Healthcare Information System (HIS) software solutions that meet our clients' unique needs. Our team works closely with clients to understand their specific requirements and develop software solutions that streamline their operations, increase efficiency, and improve patient care.`,
  },
  {
    title: "Where are we located",
    content: `We are primarily located at Qatar`,
  },
  {
    title: "How to contact us",
    content: `You can reach us at info@agilehealthcon.com or call us at +974 505 78 141`,
  },
];

export default faq;
