import { Box } from "@mui/material";

import {
  AboutUsLanding,
  CarousalBanner,
  ContactUsLanding,
  FixedBackgroundDiv,
    HollowDiv,
  ServicesLanding,
  FAQContainerLanding,
} from "../../containers";
import { MainLayout } from "../../layouts";

export const HomePage = () => {
  return (
    <MainLayout>
      <CarousalBanner />
      <AboutUsLanding />
      <ServicesLanding />
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        {/* <HollowDiv /> */}
      </Box>
      <ContactUsLanding />
      <FAQContainerLanding />
      {/* <FixedBackgroundDiv /> */}
    </MainLayout>
  );
};

export default HomePage;
