import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { navigationItems } from "../../constants";

interface MenuItem {
  label: string;
  link?: string;
  onClick?: () => {};
  icon?: ReactNode;
}

type MenuItems = MenuItem[];

interface MenuItemGroup {
  title: string;
  items: MenuItems;
}

const aboutMenuItems = [
  navigationItems.aboutus,
  navigationItems.contactus,
  navigationItems.faq,
];

const serviceMenuItems = [
  navigationItems.revenueCycleManagementService,
  navigationItems.healthcareAccreditationService,
  navigationItems.healthcareFacilityLicensingService,
  navigationItems.hishmsSoftwareService,
];

const menuGroups: MenuItemGroup[] = [
  {
    title: "Our Services",
    items: serviceMenuItems,
  },
  {
    title: "About Us",
    items: aboutMenuItems,
  },
];

export const Menu = ({ onMenuItemClick }: any) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px;",
        marginTop: "-10px",
        borderRadius: "10px",
        my: 1,
        py: 2,
        position: "absolute",
        width: "100%",
        maxWidth: "80vw",
      }}
    >
      <Container>
        <Grid container>
          {menuGroups.map((group) => (
            <Grid item xs={12} sm={4}>
              {
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      py: 2,
                      fontWeight: "bold",
                    }}
                  >
                    {group.title}
                  </Typography>
                  {group.items.map((item) => (
                    <Link to={item?.link ?? ""} onClick={onMenuItemClick}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            marginRight: 2,
                          }}
                        >
                          {item.icon}
                        </Box>
                        <Typography
                          sx={{
                            py: 1,
                            borderBottom: "2px solid transparent",
                            cursor: "pointer",
                            width: "max-content",
                            "&:hover": {
                              borderBottom: "2px solid #39C2AA",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                    </Link>
                  ))}
                </Box>
              }
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Menu;
