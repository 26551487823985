import { ReactNode, useEffect, useState } from "react";
import { Box } from "@mui/material";
import BackdropLoader from "../../components/BackdropLoader";
import GotoTop from "../../components/GotoTop";
import { Navbar } from "../../components";
import { Footer } from "../../containers";
import { usePageScroll } from "../../hooks";

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const { scrolled } = usePageScroll();

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  return (
    <>
      <Navbar />
      {/** Navbar space filler*/}
      <Box
        sx={{
          height: {
            xs: "50px",
            sm: "100px",
          },
        }}
      ></Box>
      <Box>
        {!pageLoaded && <BackdropLoader />}
        <Box>{children}</Box>
      </Box>
      <Footer />
      {scrolled && <GotoTop />}
    </>
  );
};

export default MainLayout;
