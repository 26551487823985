import { Box, Container, Grid, Typography } from "@mui/material";
import { PaddedBox } from "../../components";
import { Heading } from "../../ui";
import { serviceContent } from "./data";

interface ServiceDetailsProps {
  details?: {
    key: string;
    title: string;
    description: string;
    image: string;
  };
}

export const ServiceDetail = ({ details: service }: ServiceDetailsProps) => {
  return (
    <PaddedBox>
      <Container>
        <Box
          sx={{
            width: "100%",
            backgroundImage: `url('../${service?.image}')`,
            backgroundSize: "cover",
            minHeight: "400px",
          }}
        ></Box>

        <Box
          sx={{
            py: 3,
          }}
        >
          <Heading>{service?.title}</Heading>
          <Typography
            sx={{
              py: 3,
            }}
          >
            {service?.key &&
              serviceContent.find((item) => item.key === service.key)?.content}
          </Typography>
        </Box>
      </Container>
    </PaddedBox>
  );
};

export default ServiceDetail;
