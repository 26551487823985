import { SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

interface HeadingProps {
  children: ReactNode;
  sx?: SxProps;
}

export const Heading = ({
  children,
  sx: incomingSx,
  ...rest
}: HeadingProps) => {
  return (
    <Typography
      sx={{
        fontSize: {
          xs: "24px",
          sm: "32px",
        },
        fontWeight: "bold",
        py: 1,
        my: 2,
        width: "max-content",
        ...incomingSx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};
