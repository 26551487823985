import {
  Article,
  AssignmentTurnedIn,
  CurrencyExchange,
  Devices,
  Image,
  Info,
  LiveHelp,
  Phone,
} from "@mui/icons-material";

export const serviceNavigationItems = {
  revenueCycleManagementService: {
    key: "revenue-cycle-management",
    label: "Revenue Cycle Management",
    link: "/services/revenue-cycle-management",
    icon: <CurrencyExchange />,
  },
  healthcareAccreditationService: {
    key: "healthcare-accreditations",
    label: "Healthcare Accreditation",
    link: "/services/healthcare-accreditations",
    icon: <AssignmentTurnedIn />,
  },
  healthcareFacilityLicensingService: {
    key: "healthcare-facility-licensing",
    label: "Healthcare Facility Licensing",
    link: "/services/healthcare-facility-licensing",
    icon: <Article />,
  },
  hishmsSoftwareService: {
    key: "hms-his-software",
    label: "HIS HMS Software",
    link: "/services/hms-his-software",
    icon: <Devices />,
  },
};

export const navigationItems = {
  home: {
    key: "home",
    label: "Home",
    link: "/",
  },
  services: {
    key: "services",
    label: "Services",
    link: "/services",
  },
  aboutus: {
    key: "aboutus",
    label: "About Us",
    link: "/aboutus",
    icon: <Info />,
  },
  contactus: {
    key: "contactus",
    label: "Contact Us",
    link: "/contactus",
    icon: <Phone />,
  },
  faq: {
    key: "faq",
    label: "FAQ",
    link: "/faq",
    icon: <LiveHelp />,
  },
  ...serviceNavigationItems,
};

export const services = [
  {
    id: "revenue-cycle-management",
    title: "Revenue Cycle Management",
    description:
      "We provide ICD and CPT coding services, reconciliation of existing insurance claims, and denial management.",
    image: "assets/img/revenue-cycle-management.jpg",
    link: "/services/revenue-cycle-management",
  },
  {
    id: "healthcare-accreditations",
    title: "Healthcare Accreditations",
    description:
      "We offer guidance on international healthcare accreditations, such as JCI, Canadian accreditations, NABL, ISO, and CAP. Our consultants have extensive decades of experience working with healthcare facilities to meet accreditation requirements and improve their overall quality of care.",
    shortDescription:
      "We offer guidance on international healthcare accreditations, such as JCI, Canadian accreditations, NABL, ISO, and CAP.",
    image: "assets/img/healthcare_accreditation.jpg",
    link: "/services/healthcare-accreditations",
  },
  {
    id: "healthcare-facility-licensing",
    title: "Healthcare Facility Licensing",
    description:
      "Our team provides end-to-end support to healthcare providers, from facility design with regulatory compliance and licensing",
    shortDescription:
      "Our team provides end-to-end support to healthcare providers, from facility design with regulatory compliance and licensing",
    image: "assets/img/healthcare_facility_licensing.jpg",
    link: "/services/healthcare-facility-licensing",
  },
  {
    id: "hms-his-software",
    title: "HMS and HIS Software",
    description:
      "We provide customized Hospital Management System (HMS) and Healthcare Information System (HIS) software solutions that meet our clients' unique needs. Our team works closely with clients to understand their specific requirements and develop software solutions that streamline their operations, increase efficiency, and improve patient care.",
    shortDescription:
      "We provide customized Hospital Management System (HMS) and Healthcare Information System (HIS) software solutions that meet our clients' unique needs.",
    image: "assets/img/hms_his_software.jpg",
    link: "/services/hms-his-software",
  },
];
