import { ArrowUpward } from "@mui/icons-material";
import { SpeedDial } from "@mui/material";

export const GotoTop = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <SpeedDial
        onClick={handleClick}
        ariaLabel=""
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          "& .MuiFab-primary": { backgroundColor: "#9fcc46", color: "white" },
        }}
        icon={<ArrowUpward />}
      ></SpeedDial>
    </>
  );
};

export default GotoTop;
