import { Email } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { Heading } from "../../ui";

export const ContactUsLanding = () => {
  return (
    <>
      {" "}
      <Box
        sx={{
          backgroundColor: "#2e2e38",
          color: "#fff",
          p: 3,
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Heading
              sx={{
                color: "white",
                width: "100%",
                textAlign: "center",
              }}
            >
              Contact Us
            </Heading>
            <Typography
              sx={{
                textAlign: "center",
                p: 2,
              }}
            >
              <Email />
              <br></br>
              Reach us at info@agilehealthcon.com
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ContactUsLanding;
