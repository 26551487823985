import { ContactUs } from "../../containers";
import { MainLayout } from "../../layouts";

export const ContactUsPage = () => {
  return (
    <MainLayout>
      <ContactUs />
    </MainLayout>
  );
};

export default ContactUsPage;
