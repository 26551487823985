import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Link to="/home">
        <img src="../../assets/img/logo.png" height={30} />
      </Link>
    </Box>
  );
};

export default Logo;
