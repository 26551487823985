import { ArrowBack } from "@mui/icons-material";
import { Box, Breadcrumbs, BreadcrumbsProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface BreadcrumbItem {
  key: string;
  label: string;
  link?: string;
}

interface BreadCrumbNavigationProps extends BreadcrumbsProps {
  backLink: string;
  items: BreadcrumbItem[];
}

const BreadCrumbItem = ({ item }: { item: BreadcrumbItem }) => {
  return <Typography>{item.label}</Typography>;
};

export const BreadCrumbNavigation = ({
  items,
  backLink,
}: BreadCrumbNavigationProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingTop : {
          xs : "10px",
          sm : "50px"
        }
      }}
    >
      <Box
        sx={{
          paddingRight: 3,
        }}
      >
        <Link to={backLink}>
          <ArrowBack />
        </Link>
      </Box>

      <Box>
        <Breadcrumbs>
          {items.map((item) =>
            item.link ? (
              <Link to={item.link}>
                <BreadCrumbItem item={item}></BreadCrumbItem>{" "}
              </Link>
            ) : (
              <BreadCrumbItem item={item}></BreadCrumbItem>
            )
          )}
        </Breadcrumbs>
      </Box>
    </Box>
  );
};

export default BreadCrumbNavigation;
