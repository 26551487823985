import { Box, Container } from "@mui/material";
import { FAQ, PaddedBox } from "../../components";
import { faq } from "../../data";
import { Heading } from "../../ui";

export const FAQContainer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
      }}
    >
      <PaddedBox>
        <Container>
          <Heading>Frequently Asked Questions</Heading>
          <FAQ items={faq} />
        </Container>
      </PaddedBox>
    </Box>
  );
};

export default FAQContainer;
