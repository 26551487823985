import { Container } from "@mui/material";
import { BreadCrumbNavigation, PaddedBox } from "../../components";
import { navigationItems } from "../../constants";
import { FAQContainer } from "../../containers";
import { MainLayout } from "../../layouts";

const breadCrumbItems = [navigationItems.home, navigationItems.faq];

export const FAQPage = () => {
  return (
    <MainLayout>
      <PaddedBox>
        <Container>
          <BreadCrumbNavigation
            items={breadCrumbItems}
            backLink={navigationItems.home.link}
          />
        </Container>
        <FAQContainer />
      </PaddedBox>
    </MainLayout>
  );
};

export default FAQPage;
