import { Backdrop, CircularProgress } from "@mui/material";

export const BackdropLoader = () => {
  return (
    <Backdrop open={true}>
      <CircularProgress />
    </Backdrop>
  );
};

export default BackdropLoader;
