import { Box } from "@mui/material";
import { ReactNode } from "react";

interface PaddedBoxProps {
  children: ReactNode;
}

export const PaddedBox = ({ children }: PaddedBoxProps) => {
  return (
    <Box
      sx={{
        py: {
          xs: "100px",
          sm: "50px",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default PaddedBox;
