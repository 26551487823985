import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import RightSVG from "../../assets/svg/right.svg";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "../../ui";

interface CarousalItem {
  heading: string;
  description: string;
  image: string;
  knowMoreLink?: string;
}

interface CarousalItemProps {
  item: CarousalItem;
}

const carousalItems: CarousalItem[] = [
  {
    heading: "Expertise Assured",
    description:
      "At Agile, we are committed to deliver high-quality services and achieving measurable results for our clients. Contact us today to learn how we can help your healthcare organization achieve its business objectives.",
    image: "assets/img/expertise_assured.jpg",
  },
  {
    heading: "Revenue Cycle Management",
    description:
      "We provide ICD and CPT coding services, reconciliation of existing insurance claims, and denial management. ",
    image: "assets/img/revenue-cycle-management.jpg",
    knowMoreLink: "/services/revenue-cycle-management",
  },
  {
    heading: "Healthcare Accreditations",
    description:
      "We offer guidance on international healthcare accreditations, such as JCI, Canadian accreditations, NABL, ISO, and CAP. ",
    image: "assets/img/healthcare_accreditation.jpg",
    knowMoreLink: "/services/healthcare-accreditations",
  },
  {
    heading: "Healthcare Facility Licensing",
    description:
      "Our team provides end-to-end support to healthcare providers, from facility design with regulatory compliance and licensing.",
    image: "assets/img/healthcare_facility_licensing.jpg",
    knowMoreLink: "/services/healthcare-facility-licensing",
  },
  {
    heading: "HMS and HIS Software",
    description:
      "We provide customized Hospital Management System (HMS) and Healthcare Information System (HIS) software solutions that meet our clients' unique needs.",
    image: "assets/img/hms_his_software.jpg",
    knowMoreLink: "/services/hms-his-software",
  },
];

const CarouselItem = ({ item }: CarousalItemProps) => {
  const { heading, description, image, knowMoreLink } = item;
  return (
    <Box
      sx={{
        height: {
          xs: "70vh",
          sm: "60vh",
        },
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        color: "white",
        backgroundColor: "black",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor: "rgba(0,0,0,0.6)",
              display: "flex",
              alignItems: { xs: "flex-end", sm: "center" },
              justifyContent: "center",
              height: {
                xs: "70vh",
                sm: "60vh",
              },
              textAlign: "left",
              mr: { sm: "-80px" },
              clipPath: { sm: "polygon(0 0, 100% 0%, 92% 100%, 0% 100%)" },
            }}
          >
            <Box sx={{}}>
              <Box
                sx={{
                  padding: { xs: "30px", sm: "150px" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "32px",
                      sm: "52px",
                    },
                  }}
                >
                  {heading}
                </Typography>
                <Typography>{description}</Typography>
                {knowMoreLink && (
                  <Link to={knowMoreLink}>
                    <Button>Know More</Button>
                  </Link>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </Box>
  );
};

const carousalProps = {
  autoPlay: true,
  infiniteLoop: true,
  interval: 3000,
  showArrows: true,
  showThumbs: false,
  showStatus: false,
  useKeyboardArrows: true,
  swipeable: true,
  renderArrowPrev: (clickHandler: any, hasPrev: any) => {
    return (
      <Box
        sx={{
          position: "absolute",
          left: "80px",
          top: "50%",
          zIndex: "9998",
          color: "white",
          transform: "rotate(180deg)",
          filter: "opacity(0.6)",
          cursor: "pointer",
          display: {
            xs: "none",
            sm: "block",
          },
        }}
        onClick={clickHandler}
      >
        <>
          <img src={RightSVG} style={{ width: "30px", fill: "white" }} />
        </>
      </Box>
    );
  },
  renderArrowNext: (clickHandler: any, hasNext: any) => {
    return (
      <Box
        sx={{
          position: "absolute",
          right: "80px",
          top: "50%",
          zIndex: "9998",
          color: "white",
          filter: "opacity(0.6)",
          cursor: "pointer",
          display: {
            xs: "none",
            sm: "block",
          },
        }}
        onClick={clickHandler}
      >
        <>
          <img
            src={RightSVG}
            style={{ width: "30px" }}
            onClick={clickHandler}
          />
        </>
      </Box>
    );
  },
};

export const CarousalBanner = () => {
  return (
    <Box>
      <Carousel {...carousalProps}>
        {carousalItems.map((item) => (
          <CarouselItem item={item}></CarouselItem>
        ))}
      </Carousel>
    </Box>
  );
};

export default CarousalBanner;
